import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGetCountries from 'api/useGetCountries';
import parse from 'html-react-parser';
import Loader from 'components/loader';
import ScreenTitle from 'components/screen-title';
import SelectCard from 'components/select-card';
import { Heading1 } from 'components/typography';
import { ROUTES } from 'shared/constants';
import useGetDefaultCountry from 'shared/hooks/useGetDefaultCountry';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import {
  StyledSubheading,
  CardsWrapper,
  Container,
  HomeWrapper,
} from './styles';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultCountry = useGetDefaultCountry();
  const { countries, isLoading: isCountriesLoading } = useGetCountries();
  const [, setCountry] = useSessionStorage(SESSION_STORAGE_KEYS.country);
  const [isDefaultCountryLoading, setIsDefaultCountryLoading] = useState(true);
  const isLoading = isCountriesLoading || isDefaultCountryLoading;

  const handleCountryClick = () => {
    setCountry({
      code: defaultCountry?.code,
      name: defaultCountry?.name,
      default: true,
    });
    navigate(ROUTES.structureSelect);
  };

  const navigateToCountrySelect = () => navigate(ROUTES.countrySelect);

  useEffect(() => {
    if (countries && !defaultCountry) {
      navigate(ROUTES.countrySelect);
    }
    setIsDefaultCountryLoading(false);
  }, [countries, defaultCountry]);

  useEffect(() => sessionStorage.clear(), []);

  return isLoading ? (
    <Loader />
  ) : (
    <HomeWrapper>
      <Heading1>{parse(t('heading'))}</Heading1>
      <StyledSubheading>{t('subheading')}</StyledSubheading>
      <Container>
        <ScreenTitle
          title={t('countrySelect.title')}
          tooltipText={t('countrySelect.tooltipText') ?? ''}
          tooltipWidth={165}
        />
        <CardsWrapper>
          {defaultCountry && (
            <SelectCard
              large
              title={t(`countrySelect.countries.${defaultCountry.name}`)}
              text={
                t('countrySelect.defaultCountryText', {
                  country: t(`countrySelect.countries.${defaultCountry.name}`),
                }) ?? ''
              }
              onClick={handleCountryClick}
              displayArrow
            />
          )}
          <SelectCard
            large
            title={t('countrySelect.anotherCountry')}
            text={t('countrySelect.anotherCountryText') ?? ''}
            onClick={navigateToCountrySelect}
            displayArrow
          />
        </CardsWrapper>
      </Container>
    </HomeWrapper>
  );
};

export default Home;
