import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useGetCountries from 'api/useGetCountries';

const useGetDefaultCountry = () => {
  const { i18n } = useTranslation();
  const { countries } = useGetCountries();
  const defaultCountry = useMemo(() => {
    const splitLocale = i18n.language.split('-');
    const countryCodeInLang = splitLocale[splitLocale.length - 1];

    if (countryCodeInLang && countries) {
      return countries.find((country) => country.code === countryCodeInLang);
    }
  }, [countries, i18n.language]);

  return defaultCountry;
};

export default useGetDefaultCountry;
