import InfoIcon from 'assets/info-icon';
import styled from 'styled-components';

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
`;

export const StyledIcon = styled(InfoIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
