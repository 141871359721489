import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import FormWrapper from 'components/form-wrapper';
import Steps from 'components/steps';
import BottomActions from 'features/bottom-actions';
import { CONCRETE_ROUTES } from 'shared/constants';
import useIsFormFilled from 'shared/hooks/useIsFormFilled';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { LoadbearingStructure, ProgressStep } from 'shared/types';
import { StyledForm } from './styles';
import {
  combinedSchema,
  requiredFieldsValidationSchema,
  validationSchema,
} from './validation';
import Environment from 'features/environment';
import { EnvironmentValues } from 'features/environment/types';

const emptyValues = {
  buildingFunction: undefined,
  areaToProtect: undefined,
  humidity: undefined,
  temperature: undefined,
};

const ConcreteEnvironment = () => {
  const navigate = useNavigate();
  const progressSteps = useProgressSteps(
    LoadbearingStructure.concrete
  );
  const [storedValues, setStoredValues] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteEnvironment
  );

  const isProductKnown = useIsProductKnown();
  const initialValues = storedValues ?? emptyValues;

  const navigateBack = () => {
    removeFromSession(SESSION_STORAGE_KEYS.concreteEnvironment);
    navigate(CONCRETE_ROUTES.structuralElements);
  };

  const submitEnvironmentValues = (values: EnvironmentValues) => {
    setStoredValues(values);
    navigate(CONCRETE_ROUTES.fireRating);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submitEnvironmentValues(values)}
      validationSchema={isProductKnown ? validationSchema : combinedSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }) => {
        const isFormFilled = useIsFormFilled(
          values,
          requiredFieldsValidationSchema,
          isProductKnown
        );

        return (
          <StyledForm>
            <FormWrapper>
              <Steps items={progressSteps} active={ProgressStep.environment} />
              <Environment />
            </FormWrapper>
            <BottomActions
              onBackClick={navigateBack}
              isContinueDisabled={!isFormFilled}
              hasContinueBtn
            />
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default ConcreteEnvironment;
