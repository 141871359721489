import { useTranslation } from 'react-i18next';
import FormikInput from 'components/input/formik-input';
import FormikSelect from 'components/select/formik-select';
import { BUILDING_FUNCTIONS } from './constants';
import { Wrapper } from './styles';

const EnvironmentInputs = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FormikSelect
        optional
        name="buildingFunction"
        label={t('environment.buildingFunction') ?? ''}
        emptyValue={t('environment.buildingFunctionEmpty') ?? ''}
        items={BUILDING_FUNCTIONS}
        translationKey="environment.buildingFunctions"
      />
      <FormikInput
        name="areaToProtect"
        label={t('environment.areaToProtect') ?? ''}
        placeholder={t('environment.areaToProtectPlaceholder') ?? ''}
        numberInput
        decimal
        optional
      />
    </Wrapper>
  );
};

export default EnvironmentInputs;
