import { useTranslation } from 'react-i18next';
import EnvironmentInputs from './components/environment-inputs';
import HumidityCards from './components/humidity-cards';
import TemperatureCards from './components/temperature-cards';
import { Title } from './styles';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';

const Environment = () => {
  const { t } = useTranslation();
  const isProductKnown = useIsProductKnown();

  return (
    <>
      <Title title={t('environment.title')} />
      <EnvironmentInputs />
      {!isProductKnown && (
        <>
          <HumidityCards />
          <TemperatureCards />
        </>
      )}
    </>
  );
};

export default Environment;

