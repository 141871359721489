import { useTranslation } from 'react-i18next';
import FormikInput from 'components/input/formik-input';
import FormikSelect from 'components/select/formik-select';
import { SteelCriticalTemperature } from 'pages/steel/types';
import { TEMPERATURE_OTHER } from '../../constants';
import { InputsWrapper, Wrapper } from './styles';

interface CriticalTemperatureProps {
  temperatures: SteelCriticalTemperature[];
  selectedTemperature?: string;
}

const CriticalTemperature = ({
  temperatures,
  selectedTemperature,
}: CriticalTemperatureProps) => {
  const { t } = useTranslation();
  const temperatureSelectItems = [...temperatures, TEMPERATURE_OTHER];

  return (
    <Wrapper>
      <InputsWrapper>
        <FormikSelect
          name="criticalTemperature"
          label={t('steel.fireRating.criticalTemperature') ?? ''}
          emptyValue={t('steel.fireRating.criticalTemperatureEmpty') ?? ''}
          items={temperatureSelectItems}
          tooltipText={t('steel.fireRating.criticalTemperatureTooltip') ?? ''}
          translationKey="steel.fireRating.temperature"
        />
        {selectedTemperature === TEMPERATURE_OTHER.id && (
          <FormikInput
            label={t('steel.fireRating.customCriticalTemperature') ?? ''}
            name="customCriticalTemperature"
            placeholder={
              t('steel.fireRating.criticalTemperaturePlaceholder') ?? ''
            }
            numberInput
            showLabel={false}
            maxLength={3}
          />
        )}
      </InputsWrapper>
    </Wrapper>
  );
};

export default CriticalTemperature;
