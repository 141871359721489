import ArrowIcon from 'assets/arrow-icon';
import { NumberInputButtonsWrapper } from './styles';

interface NumberInputButtonsProps {
  onUpBtnClick: () => void;
  onDownBtnClick: () => void;
}

const NumberInputButtons = ({
  onUpBtnClick,
  onDownBtnClick,
}: NumberInputButtonsProps) => (
  <NumberInputButtonsWrapper>
    <button onClick={onUpBtnClick}>
      <ArrowIcon />
    </button>
    <button onClick={onDownBtnClick}>
      <ArrowIcon />
    </button>
  </NumberInputButtonsWrapper>
);

export default NumberInputButtons;
