import { SvgProps } from 'assets/types';

const AxisIcon = ({ className }: SvgProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    version="1.2"
    viewBox="0 0 960 1280"
    width="140"
    height="198"
  >
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m167.7 98.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m382.7 98.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m593.7 98.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m167.7 958.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m382.7 958.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m593.7 958.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m167.7 528.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m593.7 528.4v1.9c-1.1 30-26 54.1-56 54.1-30.7 0-56-25.3-56-56 0-30.8 25.3-56 56-56 30.7 0 56 25.2 56 56z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m563.8 1044.2h-477.4c-33.1 0-60-26.8-60-60v-909.1c0-33.1 26.9-60 60-60h477.4c33.1 0 60 26.9 60 60v909.1c0 33.2-26.9 60-60 60z"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d=""
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m653.7 960.2h290.5"
    />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m652.3 1101.6h290.5"
    />

    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m797.5 793.2v138.2"
    />
    <path fill="#676896" d="m780.1 926.3l17.4 30.2 17.5-30.2z" />
    <path
      fill="none"
      stroke="#676896"
      strokeMiterlimit="10"
      strokeWidth="10"
      d="m797.5 1264.9v-138.2"
    />
    <path fill="#676896" d="m815 1131.8l-17.5-30.2-17.4 30.2z" />

    <path
      fill="#54679b"
      aria-label="a"
      d="m775.7 1010l-3.6-8.7q5.5-3.3 12.7-4.9 7.3-1.6 15.2-1.6 7.5 0 12 2.1 4.6 2 6.9 5.3 2.4 3.2 3.1 7 0.9 3.9 0.9 7.8 0 8.7-0.4 16.9-0.5 8.2-0.5 15.5 0 5.4 0.5 10.1 0.4 4.8 1.6 8.8h-8.5l-3.1-10.1h-0.7q-1.3 2-3.3 4-1.9 1.9-4.6 3.5-2.7 1.4-6.3 2.4-3.6 1.1-8.2 1.1-4.6 0-8.7-1.5-3.9-1.4-6.7-4-2.9-2.8-4.7-6.5-1.5-3.7-1.5-8.5 0-6.3 2.6-10.5 2.5-4.3 7.2-6.8 4.7-2.6 11.2-3.6 6.6-1.1 14.5-1.1 2 0 3.9 0 2 0 4 0.3 0.5-4.4 0.5-7.8 0-7.9-3.2-11.1-3.2-3.2-11.5-3.2-2.5 0-5.3 0.5-2.8 0.2-5.8 1-2.9 0.5-5.6 1.6-2.6 0.8-4.6 2zm16.8 49.1q4.1 0 7.2-1 3.2-1.2 5.5-2.8 2.3-1.7 3.7-3.7 1.6-2 2.3-3.9v-12q-2-0.1-4.1-0.1-2-0.1-4.1-0.1-4.4 0-8.7 0.5-4.2 0.5-7.5 1.9-3.2 1.3-5.2 3.7-1.9 2.4-1.9 6 0 5 3.6 8.3 3.6 3.2 9.2 3.2z"
    />
  </svg>
);

export default AxisIcon;
