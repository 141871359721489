import {
  countryMultiLangLinks,
  contactLinksByCountry,
  DEFAULT_CONTACT_LINK,
} from 'shared/constants';

export const getContactUrlByLocale = (locale?: string) => {
  if (!locale) {
    return;
  }

  let link = countryMultiLangLinks[locale];

  if (!link) {
    const splitLocale = locale.split('-');
    const country = splitLocale[splitLocale.length - 1];

    link = contactLinksByCountry[country];
  }

  return link ?? DEFAULT_CONTACT_LINK;
};
