import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import FormWrapper from 'components/form-wrapper';
import Steps from 'components/steps';
import BottomActions from 'features/bottom-actions';
import { STEEL_ROUTES } from 'shared/constants';
import useIsFormFilled from 'shared/hooks/useIsFormFilled';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import useSteelHasProtectedSides from 'shared/hooks/useSteelHasProtectedSides';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { LoadbearingStructure, ProgressStep } from 'shared/types';
import { EnvironmentValues } from 'features/environment/types';
import { StyledForm } from './styles';
import {
  combinedSchema,
  requiredFieldsValidationSchema,
  validationSchema,
} from './validation';
import Environment from 'features/environment';

const emptyValues = {
  buildingFunction: undefined,
  areaToProtect: undefined,
  humidity: undefined,
  temperature: undefined,
};

const SteelEnvironment = () => {
  const navigate = useNavigate();
  const hasProtectedSides = useSteelHasProtectedSides();
  const progressSteps = useProgressSteps(
    LoadbearingStructure.steel,
    hasProtectedSides
  );
  const [storedValues, setStoredValues] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelEnvironment
  );
  const isProductKnown = useIsProductKnown();
  const backRoute = hasProtectedSides
    ? STEEL_ROUTES.protectedSides
    : STEEL_ROUTES.structuralElements;

  const initialValues = storedValues ?? emptyValues;

  const handleBackClick = () => {
    removeFromSession(SESSION_STORAGE_KEYS.steelEnvironment);
    removeFromSession(SESSION_STORAGE_KEYS.steelProtectedSides);
    navigate(backRoute);
  };

  const handleSubmit = (values: EnvironmentValues) => {
    setStoredValues(values);
    navigate(STEEL_ROUTES.fireRating);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={isProductKnown ? validationSchema : combinedSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }) => {
        const isFormFilled = useIsFormFilled(
          values,
          requiredFieldsValidationSchema,
          isProductKnown
        );

        return (
          <StyledForm>
            <FormWrapper>
              <Steps items={progressSteps} active={ProgressStep.environment} />
              <Environment />
            </FormWrapper>
            <BottomActions
              onBackClick={handleBackClick}
              isContinueDisabled={!isFormFilled}
              hasContinueBtn
            />
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default SteelEnvironment;
