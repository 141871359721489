import { useTranslation } from 'react-i18next';
import QuestionMarkIcon from 'assets/question-mark-icon';
import Button from 'components/button';
import useIsMobile from 'shared/hooks/useIsMobile';
import useLocale from 'shared/hooks/useLocale';
import { getContactUrlByLocale } from './logic';

const HelpButton = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const locale = useLocale();

  const contactPageUrl = getContactUrlByLocale(locale);

  return (
    <Button
      startIcon={<QuestionMarkIcon />}
      content={t('productDetails.getHelp')}
      fullWidth={isMobile}
      href={contactPageUrl}
      openNewTab
    />
  );
};

export default HelpButton;
