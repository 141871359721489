import { useTranslation } from 'react-i18next';
import FormikSelectCard from 'components/select-card/formik-select-card';
import { CUSTOM_PROFILE } from 'pages/steel/constants';
import {
  SteelStructuralElementsValues,
  SteelProfileShape,
} from 'pages/steel/types';
import useIsMobile from 'shared/hooks/useIsMobile';
import ProfileShapeInputs from '../profile-shape-inputs';
import { SHAPE_ICONS } from './constants';
import { CardsWrapper, Title } from './styles';

interface ProfileShapesProps {
  values: SteelStructuralElementsValues;
  shapes: SteelProfileShape[];
}

const ProfileShapes = ({ values, shapes }: ProfileShapesProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const customProfileSelected = values.profileShape === CUSTOM_PROFILE;

  return (
    <>
      <Title title={t('steel.shape.title')} />
      <CardsWrapper>
        {shapes.map((shape) => (
          <div key={shape.id}>
            <FormikSelectCard
              title={t(`steel.shape.${shape.name}`)}
              selected={values.profileShape === shape.id}
              name="profileShape"
              value={shape.id}
              iconSvg={SHAPE_ICONS[shape.name]}
            />
            {isMobile && values.profileShape === shape.id && (
              <ProfileShapeInputs values={values} />
            )}
          </div>
        ))}
        <div>
          <FormikSelectCard
            title={t('steel.shape.customProfile')}
            selected={customProfileSelected}
            name="profileShape"
            iconSvg={SHAPE_ICONS[CUSTOM_PROFILE]}
            value={CUSTOM_PROFILE}
          />
          {isMobile && customProfileSelected && (
            <ProfileShapeInputs values={values} />
          )}
        </div>
      </CardsWrapper>
      {!isMobile && values.profileShape && (
        <ProfileShapeInputs values={values} />
      )}
    </>
  );
};

export default ProfileShapes;
