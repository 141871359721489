import { API_BASE_URL } from 'shared/constants';
import useLocale from 'shared/hooks/useLocale';
import useMutation from 'shared/hooks/useMutation';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { CalculationResultData } from 'shared/types';
import { ConcreteCalculationResultRequest } from '../types';

const useGetConcreteCalculations = (productId?: string) => {
  const locale = useLocale();
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);
  const [inputParametersFromResults] = useSessionStorage(
    SESSION_STORAGE_KEYS.inputParametersFromResults
  );
  const [structuralElements] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteStructuralElements
  );
  const [fireRating] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteFireRating
  );
  const [environment] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteEnvironment
  );
  const productCategory = inputParametersFromResults?.category ?? product?.category;
  const orientationId = inputParametersFromResults?.orientationId ?? structuralElements?.orientation;
  const totalAreaToProtectM2 = inputParametersFromResults?.totalAreaToProtectM2 ?? environment?.areaToProtect;
  const currentAxisDistance = inputParametersFromResults?.currentAxisDistance ?? structuralElements?.currentAxis;
  const requiredAxisDistance = inputParametersFromResults?.requiredAxisDistance ?? structuralElements?.requiredAxis;

  const request: ConcreteCalculationResultRequest = {
    orientationId,
    fireRatingId: fireRating,
    totalAreaToProtectM2: Number(totalAreaToProtectM2),
    currentAxisDistance: Number(currentAxisDistance) || 0,
    requiredAxisDistance: Number(requiredAxisDistance),
  };

  const { mutate, isLoading, data } = useMutation<
    ConcreteCalculationResultRequest,
    CalculationResultData
  >(
    `${API_BASE_URL}v1/products/concrete/${productCategory}/${locale}/${productId}/calculation-results`,
    request
  );

  return {
    calculationResult: data,
    getConcreteCalculations: mutate,
    isLoading,
  };
};

export default useGetConcreteCalculations;
