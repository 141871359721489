import Tooltip from 'components/tooltip';
import { Heading4 } from 'components/typography';
import { TitleWrapper, StyledIcon } from './styles';

interface ScreenTitleProps {
  title: string;
  tooltipText?: string;
  tooltipWidth?: number;
  className?: string;
}

const ScreenTitle = ({
  title,
  tooltipText,
  tooltipWidth,
  className,
}: ScreenTitleProps) => (
  <TitleWrapper className={className}>
    <Heading4>{title}</Heading4>
    {tooltipText && (
      <Tooltip text={tooltipText} maxWidth={tooltipWidth}>
        <StyledIcon />
      </Tooltip>
    )}
  </TitleWrapper>
);

export default ScreenTitle;
