import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGetProductsCount from 'api/useGetProductsCount';
import Loader from 'components/loader';
import SelectCard from 'components/select-card';
import Steps from 'components/steps';
import BottomActions from 'features/bottom-actions';
import { ROUTES } from 'shared/constants';
import useIsFeatureEnabled from 'shared/hooks/useIsFeatureEnabled';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { LoadbearingStructure, ProgressStep } from 'shared/types';
import { LOADBEARING_STRUCTURES_ICONS } from './constants';
import { getStructuresWithProducts } from './logic';
import {
  CardsWrapper,
  StructureSelectWrapper,
  StyledScreenTitle,
} from './styles';

const StructureSelect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const progressSteps = useProgressSteps();
  const [country] = useSessionStorage(SESSION_STORAGE_KEYS.country);
  const [, setStructure] = useSessionStorage(SESSION_STORAGE_KEYS.structure);
  const { productsCount, isLoading } = useGetProductsCount();
  const isTimberStructureDisplayed = useIsFeatureEnabled('TIMBER_FEATURE_ON');

  const backRoute = country?.default ? ROUTES.home : ROUTES.countrySelect;
  let structures = getStructuresWithProducts(productsCount ?? []);

  if (!isTimberStructureDisplayed) {
    structures = structures.filter(
      (structure) => structure !== LoadbearingStructure.timber
    );
  }

  const handleStructureClick = (structure: LoadbearingStructure) => {
    setStructure(structure);
    navigate(ROUTES.productSelect);
  };

  const handleBackClick = () => {
    removeFromSession(SESSION_STORAGE_KEYS.country);
    navigate(backRoute);
  };

  const getCardTitle = (structure: LoadbearingStructure) =>
    t(`structureSelect.${structure.toLowerCase()}`);

  const getStructureIcon = (structure: LoadbearingStructure) =>
    LOADBEARING_STRUCTURES_ICONS[structure];

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <StructureSelectWrapper>
        <Steps items={progressSteps} active={ProgressStep.structure} />
        <StyledScreenTitle title={t('structureSelect.title')} />
        <CardsWrapper>
          {structures.map((structure) => {
            return (
              <SelectCard
                key={structure}
                title={getCardTitle(structure)}
                onClick={() => handleStructureClick(structure)}
                displayArrow
                iconSvg={getStructureIcon(structure)}
              />
            );
          })}
        </CardsWrapper>
      </StructureSelectWrapper>
      <BottomActions onBackClick={handleBackClick} />
    </>
  );
};

export default StructureSelect;
