import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from 'components/header';
import IframeWrapper from 'components/iframe-wrapper';
import Loader from 'components/loader';
import MainWrapper from 'components/main-wrapper';
import ConcreteEnvironment from 'pages/concrete/environment';
import ConcreteFireRating from 'pages/concrete/fire-rating';
import ConcreteProductDetails from 'pages/concrete/product-details';
import ConcreteResults from 'pages/concrete/results';
import ConcreteStructuralElements from 'pages/concrete/structural-elements';
import CountrySelect from 'pages/country-select';
import Home from 'pages/home';
import PageNotFound from 'pages/page-not-found';
import ProductSelect from 'pages/product-select';
import SteelEnvironment from 'pages/steel/environment';
import SteelFireRating from 'pages/steel/fire-rating';
import SteelProductDetails from 'pages/steel/product-details';
import ProtectedSides from 'pages/steel/protected-sides';
import SteelResults from 'pages/steel/results';
import SteelStructuralElements from 'pages/steel/structural-elements';
import StructureSelect from 'pages/structure-select';
import { ROUTES, isInIframe } from 'shared/constants';
import useBeforeUnloadEffect from 'shared/hooks/useBeforeUnloadEffect';
import useScrollToTopEffect from 'shared/hooks/useScrollToTopEffect';
import { selectIsQueryError } from 'store/calculator/selectors/calculator';
import { setIsQueryError } from 'store/calculator/slices/calculator';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const AppContent = () => (
  <Routes>
    <Route path={ROUTES.home} element={<Home />} />
    <Route path={ROUTES.countrySelect} element={<CountrySelect />} />
    <Route path={ROUTES.structureSelect} element={<StructureSelect />} />
    <Route path={ROUTES.productSelect} element={<ProductSelect />} />
    <Route path={ROUTES.steel}>
      <Route
        path={ROUTES.structuralElements}
        element={<SteelStructuralElements />}
      />
      <Route path={ROUTES.protectedSides} element={<ProtectedSides />} />
      <Route path={ROUTES.environment} element={<SteelEnvironment />} />
      <Route path={ROUTES.fireRating} element={<SteelFireRating />} />
      <Route path={ROUTES.results} element={<SteelResults />} />
      <Route
        path={`${ROUTES.productDetails}/:productId`}
        element={<SteelProductDetails />}
      />
      <Route path="" element={<PageNotFound />} />
    </Route>
    <Route path={ROUTES.concrete}>
      <Route
        path={ROUTES.structuralElements}
        element={<ConcreteStructuralElements />}
      />
      <Route path={ROUTES.environment} element={<ConcreteEnvironment />} />
      <Route path={ROUTES.fireRating} element={<ConcreteFireRating />} />
      <Route path={ROUTES.results} element={<ConcreteResults />} />
      <Route
        path={`${ROUTES.productDetails}/:productId`}
        element={<ConcreteProductDetails />}
      />
      <Route path="" element={<PageNotFound />} />
    </Route>
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

const App = ({ isLoading }: { isLoading: boolean }) => {
  const isQueryError = useAppSelector(selectIsQueryError);
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setIsQueryError(false));
  }, [location]);

  useBeforeUnloadEffect();
  useScrollToTopEffect();

  if (isInIframe) {
    return (
      <IframeWrapper>
        <AppContent />
      </IframeWrapper>
    );
  }

  return (
    <>
      <Header />
      <MainWrapper>
        {isLoading ? (
          <Loader />
        ) : isQueryError ? (
          <PageNotFound />
        ) : (
          <AppContent />
        )}
      </MainWrapper>
    </>
  );
};

export default App;
