import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGetProducts from 'api/useGetProducts';
import ProductCategoryCardIcon from 'assets/box-icon.png';
import HelpMeChooseIcon from 'assets/help-me-choose-icon.png';
import Loader from 'components/loader';
import SelectCard from 'components/select-card';
import Steps from 'components/steps';
import Tabs from 'components/tabs';
import BottomActions from 'features/bottom-actions';
import { ROUTES } from 'shared/constants';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import {
  ImageRendition,
  Product,
  ProductCategory,
  ProgressStep,
  TabItem,
} from 'shared/types';
import getProductImage from 'shared/utils/getProductImage';
import { theme } from 'styles/theme';
import {
  getProductCategoryCardTitle,
  getProductCategories,
  getProductsByCategory,
  getRouteByStructure,
} from './logic';
import {
  HelpCardWrapper,
  ProductCardsWrapper,
  ProductSelectWrapper,
  StyledScreenTitle,
} from './styles';

const ProductSelect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const progressSteps = useProgressSteps();
  const { products, isLoading } = useGetProducts();
  const [productsToDisplay, setProductsToDisplay] = useState<Product[]>([]);
  const [productCategories, setProductCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(
    productCategories[0]
  );
  const [structure] = useSessionStorage(SESSION_STORAGE_KEYS.structure);
  const [, setProduct] = useSessionStorage(SESSION_STORAGE_KEYS.product);

  const getTabsItems = () =>
    productCategories.reduce(
      (items: TabItem[], category) => [
        ...items,
        { value: category, label: t(`product.category.${category}`) },
      ],
      []
    );

  const navigateToCalculator = () => navigate(getRouteByStructure(structure));

  const handleProductClick = (product: Product) => {
    setProduct({ id: product.id, category: product.categoryType });
    navigateToCalculator();
  };
  const handleHelpCardClick = () => {
    setProduct({ id: null, category: null });
    navigateToCalculator();
  };
  const handleTypeCardClick = (category: string) => {
    setProduct({ id: null, category });
    navigateToCalculator();
  };
  const handleBackClick = () => {
    removeFromSession(SESSION_STORAGE_KEYS.structure);
    navigate(ROUTES.structureSelect);
  };
  const handleTabChange = (category: string) => setSelectedCategory(category);

  useEffect(() => {
    if (products && products.length > 0) {
      const productCategories = getProductCategories(products);
      setProductCategories(productCategories);
      setSelectedCategory(productCategories[0]);
    }
  }, [products]);

  useEffect(() => {
    if (products && products.length > 0 && selectedCategory) {
      const productsByCategory = getProductsByCategory(
        products,
        selectedCategory
      );
      setProductsToDisplay(productsByCategory);
    }
  }, [products, selectedCategory]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <ProductSelectWrapper>
        <Steps items={progressSteps} active={ProgressStep.product} />
        <StyledScreenTitle title={t('productSelect.title')} />
        {productsToDisplay && productsToDisplay.length > 0 && (
          <>
            {products && products.length > 1 && (
              <HelpCardWrapper>
                <SelectCard
                  title={t('productSelect.helpMeChoose')}
                  displayArrow
                  iconPng={HelpMeChooseIcon}
                  onClick={handleHelpCardClick}
                  iconBackground={theme.colors.white}
                />
              </HelpCardWrapper>
            )}
            <Tabs items={getTabsItems()} onChange={handleTabChange} />
            <ProductCardsWrapper>
              {productsToDisplay.map((product) => (
                <SelectCard
                  key={product.id}
                  title={product.name}
                  info={product.shortDescription}
                  onClick={() => handleProductClick(product)}
                  tooltipMaxWidth={340}
                  displayArrow
                  image={getProductImage(
                    product.previewImageId,
                    ImageRendition.Thumbnail
                  )}
                />
              ))}
              {productsToDisplay.length > 1 && (
                <SelectCard
                  title={t('productSelect.any', {
                    category: getProductCategoryCardTitle(
                      selectedCategory as ProductCategory,
                      t
                    ),
                  })}
                  displayArrow
                  onClick={() => handleTypeCardClick(selectedCategory)}
                  iconPng={ProductCategoryCardIcon}
                  iconBackground={theme.colors.white}
                />
              )}
            </ProductCardsWrapper>
          </>
        )}
      </ProductSelectWrapper>
      <BottomActions onBackClick={handleBackClick} />
    </>
  );
};

export default ProductSelect;
