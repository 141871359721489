import { useTranslation } from 'react-i18next';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import getLanguageAndCountryFromLocale from 'shared/utils/getLanguageAndCountryFromLocale';
import useSessionStorage from './useSessionStorage';

const useLocale = () => {
  const { i18n } = useTranslation();
  const [country] = useSessionStorage(SESSION_STORAGE_KEYS.country);
  const { languageCode } = getLanguageAndCountryFromLocale(i18n.language);
  const countryCode = country?.code;

  return languageCode && countryCode
    ? `${languageCode}-${countryCode}`
    : undefined;
};

export default useLocale;
