import { TFunction } from 'i18next';
import {
  BoardsCalculationResult,
  CalculationResultData,
  CalculationResultType,
  PaintsCalculationResult,
  SpraysCalculationResult,
} from 'shared/types';

const filterCalculationResult = (calculationResult: CalculationResultData) => {
  const calculationDetails = { ...calculationResult };

  switch (calculationResult.$type) {
    case CalculationResultType.Board: {
      const { boardThickness, boardLayers } =
        calculationResult as BoardsCalculationResult;

      return {
        boardThickness,
        boardLayers,
      };
    }
    case CalculationResultType.Spray: {
      const { sprayThickness, amountNeededTotal } =
        calculationResult as SpraysCalculationResult;

      return {
        sprayThickness,
        amountNeededTotal,
      };
    }
    case CalculationResultType.Paint: {
      const { dryFilmThickness, amountNeededTotal } =
        calculationResult as PaintsCalculationResult;

      return {
        dryFilmThickness,
        amountNeededTotal,
      };
    }
    default:
      delete calculationDetails.testReportNo;

      if ('estimatedApplicationTime' in calculationDetails) {
        delete calculationDetails.estimatedApplicationTime;
      }

      if ('estimatedInstallationTime' in calculationDetails) {
        delete calculationDetails.estimatedInstallationTime;
      }

      return calculationDetails;
  }
};

const mapCalculationResult = (
  t: TFunction,
  calculationResult?: CalculationResultData
) => {
  if (!calculationResult) {
    return [];
  }

  const filteredCalculationResult = filterCalculationResult(calculationResult);

  return (
    Object.keys(filteredCalculationResult) as Array<keyof CalculationResultData>
  ).map((detailKey) => {
    const calculationDetail = calculationResult[detailKey];
    let calculationDetailValueStr = '';

    if (calculationDetail && typeof calculationDetail !== 'string') {
      if (Array.isArray(calculationDetail.value)) {
        calculationDetailValueStr = calculationDetail.value.join(' + ');
      } else {
        calculationDetailValueStr = `${calculationDetail.value}`;
      }

      return {
        name: t(`productDetails.${detailKey}`),
        value: calculationDetail.unit
          ? `${calculationDetailValueStr} ${calculationDetail.unit}`
          : calculationDetailValueStr,
      };
    }

    return {
      name: t(`productDetails.${detailKey}`),
      value: t('productDetails.notKnown'),
    };
  });
};

export default mapCalculationResult;
