import { useTranslation } from 'react-i18next';
import FormikInput from 'components/input/formik-input';
import {
  ContentWrapper,
  InputsWrapper,
  StyledAxisIcon,
  Wrapper,
} from './styles';

const AxisInformationInputs = () => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <Wrapper>
        <StyledAxisIcon />
        <InputsWrapper>
          <FormikInput
            name="currentAxis"
            label={t('concrete.structuralElements.currentAxis') ?? ''}
            placeholder={t('concrete.structuralElements.axisPlaceholder') ?? ''}
            numberInput
            maxLength={5}
            optional
            tooltipText={t('concrete.structuralElements.tooltipText') ?? ''}
          />
          <FormikInput
            name="requiredAxis"
            label={t('concrete.structuralElements.requiredAxis') ?? ''}
            placeholder={t('concrete.structuralElements.axisPlaceholder') ?? ''}
            numberInput
            maxLength={5}
          />
        </InputsWrapper>
      </Wrapper>
    </ContentWrapper>
  );
};

export default AxisInformationInputs;
