import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import FormWrapper from 'components/form-wrapper';
import Loader from 'components/loader';
import Steps from 'components/steps';
import BottomActions from 'features/bottom-actions';
import { StyledForm } from 'pages/steel/environment/styles';
import { CONCRETE_ROUTES, ROUTES } from 'shared/constants';
import useIsFormFilled from 'shared/hooks/useIsFormFilled';
import useIsMobile from 'shared/hooks/useIsMobile';
import useProgressSteps from 'shared/hooks/useProgressSteps';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { LoadbearingStructure, ProgressStep } from 'shared/types';
import useGetConcreteOrientations from '../api/useGetConcreteOrientations';
import { ConcreteStructuralElementsValues } from '../types';
import AxisInformationInputs from './components/axis-information-inputs';
import Orientations from './components/orientations';
import useValidation from './useValidation';

const emptyValues = {
  orientation: undefined,
  depth: undefined,
  width: undefined,
  currentAxis: undefined,
  requiredAxis: undefined,
};

const ConcreteStructuralElements = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { concreteOrientations, isLoading } = useGetConcreteOrientations();
  const progressSteps = useProgressSteps(LoadbearingStructure.concrete);
  const [storedValues, setStoredValues] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteStructuralElements
  );
  const { validationSchema, requiredFieldsValidationSchema } = useValidation();

  const initialValues = storedValues ?? emptyValues;

  const handleBackClick = () => {
    removeFromSession(SESSION_STORAGE_KEYS.product);
    removeFromSession(SESSION_STORAGE_KEYS.concreteStructuralElements);
    navigate(ROUTES.productSelect);
  };

  const handleSubmit = (values: ConcreteStructuralElementsValues) => {
    setStoredValues(values);
    navigate(CONCRETE_ROUTES.environment); };

  return isLoading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, resetForm }) => {
        const isFormFilled = useIsFormFilled(
          values,
          requiredFieldsValidationSchema
        );

        useEffect(() => {
          if (storedValues && storedValues.orientation !== values.orientation) {
            setStoredValues(null);
            resetForm({
              values: {
                ...emptyValues,
                orientation: values.orientation,
              },
            });
          } else {
            resetForm({
              values: {
                ...initialValues,
                orientation: values.orientation,
              },
            });
          }
        }, [values.orientation]);

        return (
          <StyledForm>
            <FormWrapper>
              <Steps
                items={progressSteps}
                active={ProgressStep.structuralElements}
              />
              <Orientations
                values={values}
                orientations={concreteOrientations!}
              />
              {!isMobile && values.orientation && <AxisInformationInputs />}
            </FormWrapper>
            <BottomActions
              onBackClick={handleBackClick}
              hasContinueBtn
              isContinueDisabled={!isFormFilled}
            />
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default ConcreteStructuralElements;
