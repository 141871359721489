import { useMemo } from 'react';
import useQuery from 'shared/hooks/useQuery';
import { Country } from 'shared/types';

const useGetCountries = () => {
  const { data, error, isLoading, isError } = useQuery<Country[]>(
    'countries',
    'v1/countries',
    [],
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const filteredData = useMemo(
    () => data?.filter((country) => country.name !== 'France'),
    [data]
  );

  return {
    countries: filteredData,
    error,
    isLoading,
    isError,
  };
};

export default useGetCountries;
