import { useTranslation } from 'react-i18next';
import useGetLanguages from 'api/useGetLanguages';
import PromatLogo from 'assets/promat-logo.png';
import LanguageSelect from 'features/language-select';
import { DEFAULT_LANGUAGE, ROUTES } from 'shared/constants';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import getLanguageAndCountryFromLocale from 'shared/utils/getLanguageAndCountryFromLocale';
import {
  CountryText,
  HeaderContent,
  SelectWrapper,
  StyledHeader,
  StyledImg,
  StyledLink,
} from './styles';

const Header = () => {
  const { t, i18n } = useTranslation();
  const { languages } = useGetLanguages();
  const country = sessionStorage.getItem(SESSION_STORAGE_KEYS.country);
  const { languageCode } = getLanguageAndCountryFromLocale(i18n.language);
  const selectedLanguage =
    languages?.find((language) => language.code === languageCode)?.code ??
    DEFAULT_LANGUAGE;

  return (
    <StyledHeader>
      <HeaderContent>
        <StyledLink to={ROUTES.home}>
          <StyledImg src={PromatLogo} alt="Promat logo" />
        </StyledLink>
        {languages && selectedLanguage && (
          <SelectWrapper>
            <LanguageSelect
              languages={languages}
              selectedLanguage={selectedLanguage}
            />
          </SelectWrapper>
        )}
        {country && (
          <CountryText>
            {t('countrySelect.market', {
              countryName: t(
                `countrySelect.countries.${JSON.parse(country).name}`
              ),
            })}
          </CountryText>
        )}
      </HeaderContent>
    </StyledHeader>
  );
};

export default Header;
