import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGetCountries from 'api/useGetCountries';
import CountryCard from 'components/country-card';
import Loader from 'components/loader';
import ScreenTitle from 'components/screen-title';
import BottomActions from 'features/bottom-actions';
import { ROUTES } from 'shared/constants';
import useGetDefaultCountry from 'shared/hooks/useGetDefaultCountry';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { Country } from 'shared/types';
import { CountryGrid, CountrySelectWrapper } from './styles';

const CountrySelect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { countries, isLoading } = useGetCountries();
  const defaultCountry = useGetDefaultCountry();
  const [, setCountry] = useSessionStorage(SESSION_STORAGE_KEYS.country);

  const handleCountryClick = (country: Country) => {
    setCountry({
      code: country.code,
      name: country.name,
      default: false,
    });
    navigate(ROUTES.structureSelect);
  };

  const navigateBack = () => navigate(ROUTES.home);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CountrySelectWrapper>
        <ScreenTitle
          title={t('countrySelect.title')}
          tooltipText={t('countrySelect.tooltipText') ?? ''}
          tooltipWidth={165}
        />
        <CountryGrid>
          {countries &&
            countries.map((country) => (
              <CountryCard
                key={country.name}
                title={t(`countrySelect.countries.${country.name}`)}
                onClick={() => handleCountryClick(country)}
                iconSvgString={country.flagIconSvg}
              />
            ))}
        </CountryGrid>
      </CountrySelectWrapper>
      <BottomActions
        onBackClick={navigateBack}
        hasBackButton={!!defaultCountry}
      />
    </>
  );
};

export default CountrySelect;
