import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useGetProductDetails from 'api/useGetProductDetails';
import Carousel from 'components/carousel';
import HelpButton from 'components/help-button';
import Loader from 'components/loader';
import Tabs from 'components/tabs';
import TopActions from 'features/top-actions';
import useIsMobile from 'shared/hooks/useIsMobile';
import {
  SESSION_STORAGE_KEYS,
  removeFromSession,
} from 'shared/sessionStorageUtils';
import { CalculationResultData, InputDetailsData } from 'shared/types';
import CalculationDetails from './components/calculation-details';
import DownloadPdf from './components/download-pdf';
import InputDetails from './components/input-details';
import InstallationDetails from './components/installation-details';
import ProductDescription from './components/product-description';
import { getProductDetailsTabs, getTabContent } from './logic';
import {
  Wrapper,
  ContentLeft,
  Heading,
  MobileWrapper,
  TabContentWrapper,
  ContentRight,
  ProductNotTestedText,
  CalculationDetailsWrapper,
} from './styles';
import { ProductDetailsSection } from './types';

interface ProductDetailsProps {
  calculationResult?: CalculationResultData;
  inputDetails: InputDetailsData;
  isCalculationResultLoading?: boolean;
  backRoute: string;
}

const ProductDetails = ({
  calculationResult,
  inputDetails,
  isCalculationResultLoading,
  backRoute,
}: ProductDetailsProps) => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const tabs = getProductDetailsTabs(t, calculationResult);
  const [selectedSection, setSelectedSection] = useState(
    ProductDetailsSection.calculationResult
  );
  const { productDetails, isLoading: isProductDetailsLoading } =
    useGetProductDetails(productId);

  const isLoading = isProductDetailsLoading || isCalculationResultLoading;

  const selectProductDetailsSection = (section: string) =>
    setSelectedSection(section as ProductDetailsSection);

  const handleBackClick = () => {
    removeFromSession(SESSION_STORAGE_KEYS.inputParametersFromResults);
    navigate(backRoute);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <TopActions onBackClick={handleBackClick} title={productDetails?.name} />
      {isMobile ? (
        <MobileWrapper>
          {calculationResult ? (
            <>
              <Carousel items={productDetails?.imageIds} />
              <Tabs
                items={tabs}
                onChange={selectProductDetailsSection}
                fullWidth
              />
              <TabContentWrapper>
                {getTabContent(
                  selectedSection,
                  productDetails,
                  calculationResult,
                  inputDetails
                )}
              </TabContentWrapper>
            </>
          ) : (
            <>
              <ProductNotTestedText>
                {t('productDetails.productNotTested')}
              </ProductNotTestedText>
              <HelpButton />
            </>
          )}
        </MobileWrapper>
      ) : (
        <>
          <Heading>{productDetails?.name}</Heading>
          {calculationResult ? (
            <Wrapper>
              <ContentLeft>
                <Carousel items={productDetails?.imageIds} />
                <ProductDescription productDetails={productDetails} />
              </ContentLeft>
              <ContentRight>
                {calculationResult && (
                  <CalculationDetailsWrapper>
                    <CalculationDetails calculationResult={calculationResult} />
                    <DownloadPdf
                      calculationResult={calculationResult}
                      productDetails={productDetails}
                      inputDetails={inputDetails}
                    />
                  </CalculationDetailsWrapper>
                )}
                <InputDetails inputDetails={inputDetails} />
                <InstallationDetails
                  productDetails={productDetails}
                  testReportNo={calculationResult.testReportNo}
                />
              </ContentRight>
            </Wrapper>
          ) : (
            <>
              <ProductNotTestedText>
                {t('productDetails.productNotTested')}
              </ProductNotTestedText>
              <HelpButton />
            </>
          )}
        </>
      )}
    </div>
  );
};
export default ProductDetails;
