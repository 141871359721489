import { styled } from 'styled-components';
import { Body2, Heading3 } from 'components/typography';
import { breakpoint, spacing } from 'styles/helpers';
import { TOP_ACTIONS_HEIGHT_MOBILE } from 'styles/theme';

export const Wrapper = styled.div`
  display: grid;
  gap: ${spacing['3xl']};
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

export const MobileWrapper = styled.div`
  margin-top: ${TOP_ACTIONS_HEIGHT_MOBILE};
  padding: ${spacing.s} ${spacing.m} 0;
`;

export const TabContentWrapper = styled.div`
  padding: ${spacing.l} 0;
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentRight = styled.div`
  display: grid;
  gap: ${spacing['3xl']};
  height: fit-content;
`;

export const Heading = styled(Heading3)`
  color: ${({ theme }) => theme.colors.primary};
  margin: ${spacing.s} 0;
`;

export const ProductNotTestedText = styled(Body2)`
  margin: ${spacing.s} 0;
`;

export const CalculationDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['2xs']};

  ${breakpoint.mobile} {
    gap: ${spacing.l};
  }
`;
