import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/loader';
import ResultsGrid from 'features/results-grid';
import ResultsRestartButton from 'features/results-restart-button';
import TopActions from 'features/top-actions';
import { STEEL_ROUTES } from 'shared/constants';
import useIsMobile from 'shared/hooks/useIsMobile';
import useRestartCalculator from 'shared/hooks/useRestartCalculator';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { CalculationInputParamsData, SteelCalculationInputParamsData } from 'shared/types';
import useGetSteelCalculationsList from '../api/useGetSteelCalculationsList';
import { MobileWrapper, Title } from './styles';

const SteelResults = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
    const restartCalculator = useRestartCalculator();

  const [, setInputParamsFromResults] = useSessionStorage(
    SESSION_STORAGE_KEYS.inputParametersFromResults
  );
  const {
    productsWithCalculations,
    getSteelCalculationsList,
    isLoading: isCalculationsListLoading,
  } = useGetSteelCalculationsList();

  const navigateBack = () => navigate(STEEL_ROUTES.fireRating);

  const viewProductDetails = (inputParams : CalculationInputParamsData) => {
    const steelInputParams  = inputParams as SteelCalculationInputParamsData;
      setInputParamsFromResults({
          productId: steelInputParams.productId,
          category: steelInputParams.category,
          fireRatingId: steelInputParams.fireRatingId,
          orientationId: steelInputParams.orientationId,
          totalAreaToProtectM2: steelInputParams.totalAreaToProtectM2,
          criticalTemperatureId: steelInputParams.criticalTemperatureId,
          criticalTemperatureC: steelInputParams.criticalTemperatureC,
          crossSectionalShapeId: steelInputParams.crossSectionalShapeId,
          sectionFactor: steelInputParams.sectionFactor,
          profileSubTypeId: steelInputParams.profileSubTypeId,
          protectedSideId: steelInputParams.protectedSideId
      });
      navigate(`${STEEL_ROUTES.productDetails}/${steelInputParams.productId}`);
  };

  useEffect(() => {
    getSteelCalculationsList();
    setIsInitialLoading(false);
  }, []);

  return isInitialLoading || isCalculationsListLoading ? (
    <Loader />
  ) : (
    <div>
      <TopActions
        title={t('results.title') ?? ''}
        onBackClick={navigateBack}
        rightAction={<ResultsRestartButton onClick={restartCalculator} />}
      />
      {isMobile ? (
        <MobileWrapper>
          <ResultsGrid
            products={productsWithCalculations}
            onViewProductDetails={viewProductDetails}
          />
        </MobileWrapper>
      ) : (
        <>
          <Title title={t('results.title')} />
          <ResultsGrid
            products={productsWithCalculations}
            onViewProductDetails={viewProductDetails}
          />
        </>
      )}
    </div>
  );
};

export default SteelResults;
