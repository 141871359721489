import { ChangeEvent, useState } from 'react';
import { Input as InputMui, InputAdornment } from '@mui/material';
import parse from 'html-react-parser';
import InputHelperText from 'components/input-helper-text';
import InputLabel from 'components/input-label';
import NumberInputButtons from './number-input-buttons';
import { inputStyles } from './styles';

export interface InputProps {
  optional?: boolean;
  type?: 'number' | 'text';
  error?: boolean;
  label?: string;
  helperText?: string;
  small?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  numberInput?: boolean;
  value?: string | number;
  name?: string;
  decimal?: boolean;
  showLabel?: boolean;
  maxLength?: number;
  tooltipText?: string;
  id?: string;
}

const Input = ({
  optional,
  type = 'text',
  error = false,
  label,
  helperText,
  small = false,
  placeholder,
  onChange,
  numberInput = false,
  value,
  decimal,
  showLabel = true,
  maxLength,
  tooltipText,
  id,
}: InputProps) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numRegex = decimal ? /^[0-9]{0,5}(\.[0-9]{0,2})?$/ : /^[0-9]+$/;

    if (
      !numberInput ||
      e.target.value === '' ||
      numRegex.test(e.target.value)
    ) {
      setInputValue(e.target.value);
      onChange?.(e.target.value);
    }
  };

  const handleUpBtnClick = () => setInputValue(Number(value) + 1);
  const handleDownBtnClick = () =>
    value !== 0 && setInputValue(Number(value) - 1);

  return (
    <div>
      {label && showLabel && (
        <InputLabel
          id={`${id}-label`}
          label={label}
          optional={optional}
          tooltipText={tooltipText}
        />
      )}
      <InputMui
        aria-label={label}
        id={id}
        fullWidth
        value={value ?? inputValue}
        onChange={handleChange}
        error={error}
        placeholder={placeholder ? `${parse(placeholder)}` : ''}
        type={type}
        sx={inputStyles(small)}
        inputProps={{ maxLength }}
        endAdornment={
          type === 'number' && (
            <InputAdornment position="end">
              <NumberInputButtons
                onUpBtnClick={handleUpBtnClick}
                onDownBtnClick={handleDownBtnClick}
              />
            </InputAdornment>
          )
        }
      />
      {helperText && <InputHelperText error={error} helperText={helperText} />}
    </div>
  );
};

export default Input;
