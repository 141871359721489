import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from 'shared/constants';
import useLocale from 'shared/hooks/useLocale';
import useMutation from 'shared/hooks/useMutation';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { CalculationResultsItem } from 'shared/types';
import mapCalculationResult from 'shared/utils/mapCalculationResult';
import { ConcreteCalculationsListRequest } from '../types';

const useGetConcreteCalculationsList = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const [product] = useSessionStorage(SESSION_STORAGE_KEYS.product);
  const [structuralElements] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteStructuralElements
  );
  const [fireRating] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteFireRating
  );
  const [environment] = useSessionStorage(
    SESSION_STORAGE_KEYS.concreteEnvironment
  );
  const productCategory = product?.category;
  const orientationId = structuralElements?.orientation;
  const totalAreaToProtectM2 = environment?.areaToProtect;
  const currentAxisDistance = structuralElements?.currentAxis;
  const requiredAxisDistance = structuralElements?.requiredAxis;
  const environmentHumidity = environment?.humidity;
  const environmentTemperature = environment?.temperature;

  const request: ConcreteCalculationsListRequest = {
    orientationId,
    fireRatingId: fireRating,
    totalAreaToProtectM2: Number(totalAreaToProtectM2),
    currentAxisDistance: Number(currentAxisDistance) || 0,
    requiredAxisDistance: Number(requiredAxisDistance),
    environmentHumidity,
    environmentTemperature,
    productCategory,
  };

  const { mutate, isLoading, data } = useMutation<
    ConcreteCalculationsListRequest,
    CalculationResultsItem[]
  >(
    `${API_BASE_URL}v1/products/concrete/${locale}/calculation-results`,
    request
  );

  const productsWithCalculations = data?.map((product) => ({
    ...product,
    calculationResult: mapCalculationResult(t, product.calculationResult),
  }));

  return {
    productsWithCalculations,
    getConcreteCalculationsList: mutate,
    isLoading,
  };
};
export default useGetConcreteCalculationsList;
