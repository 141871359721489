import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductDetails from 'features/product-details';
import { CONCRETE_ROUTES } from 'shared/constants';
import useGetSessionInputDetails from 'shared/hooks/useGetSessionInputDetails';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { ConcreteInputDetailsData } from 'shared/types';
import { sortInputDetails } from 'shared/utils/sortInputDetails';
import useGetConcreteCalculations from '../api/useGetConcreteCalculations';
import useGetConcreteInputDetails from '../api/useGetConcreteInputDetails';
import { concreteInputDetailsSortOrder } from './constants';

const ConcreteProductDetails = () => {
  const { productId } = useParams();
  const isProductKnown = useIsProductKnown();
  const {
    calculationResult,
    getConcreteCalculations,
    isLoading: isCalculationResultLoading,
  } = useGetConcreteCalculations(productId);

  const {
    inputDetails,
    getConcreteInputDetails,
    isLoading: isInputDetailsLoading,
  } = useGetConcreteInputDetails();

  const backRoute = isProductKnown
    ? CONCRETE_ROUTES.fireRating
    : CONCRETE_ROUTES.results;

  useEffect(() => {
    getConcreteCalculations();
    getConcreteInputDetails();
  }, []);

  const sessionInputDetails = useGetSessionInputDetails(
    SESSION_STORAGE_KEYS.concreteEnvironment
  );

  const concreteInputDetails = { ...inputDetails, ...sessionInputDetails };

  const sortedInputDetails = sortInputDetails(
    concreteInputDetailsSortOrder,
    concreteInputDetails as ConcreteInputDetailsData
  );

  const isProductDataLoading =
    isCalculationResultLoading || isInputDetailsLoading;

  return (
    <ProductDetails
      backRoute={backRoute}
      inputDetails={sortedInputDetails}
      calculationResult={calculationResult}
      isCalculationResultLoading={isProductDataLoading}
    />
  );
};
export default ConcreteProductDetails;
