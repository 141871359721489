const getLanguageAndCountryFromLocale = (locale: string) => {
  const splitLanguage = locale.split('-');
  const hasCountryCode =
    splitLanguage.length > 1 &&
    splitLanguage[splitLanguage.length - 1].length === 2;

  if (hasCountryCode) {
    const [languageCode, countryCode] =
      splitLanguage.length === 3
        ? [splitLanguage.slice(0, 2).join('-'), splitLanguage[2]]
        : splitLanguage;

    return { languageCode, countryCode, hasCountryCode };
  }
  return { languageCode: locale, hasCountryCode };
};

export default getLanguageAndCountryFromLocale;
