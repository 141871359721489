import { useTranslation } from 'react-i18next';
import HelpButton from 'components/help-button';
import ProductCard from 'components/product-card';
import { CalculationInputParamsData, ProductWithCalculationDetails } from 'shared/types';
import { CardsWrapper, NoResultsText } from './styles';

interface ResultsGridProps {
  products?: ProductWithCalculationDetails[];
  onViewProductDetails: (inputParams: CalculationInputParamsData) => void;
}

const ResultsGrid = ({ products, onViewProductDetails }: ResultsGridProps) => {
  const { t } = useTranslation();

  return products?.length ? (
    <CardsWrapper>
      {products.map((product) => (
        <ProductCard
          key={product.productDetails.id}
          product={product}
          onClick={() => onViewProductDetails(product.calculationInputParameters)}
        />
      ))}
    </CardsWrapper>
  ) : (
    <>
      <NoResultsText>{t('results.noResults')}</NoResultsText>
      <HelpButton />
    </>
  );
};
export default ResultsGrid;
