import { API_BASE_URL } from 'shared/constants';
import useMutation from 'shared/hooks/useMutation';
import useSessionStorage from 'shared/hooks/useSessionStorage';
import useSteelHasProtectedSides from 'shared/hooks/useSteelHasProtectedSides';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { SteelInputDetailsData } from 'shared/types';
import { SteelInputDetailsRequest } from '../types';

const useGetSteelInputDetails = () => {
  const [inputParametersFromResults] = useSessionStorage(
    SESSION_STORAGE_KEYS.inputParametersFromResults
  );
  const [structuralElements] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelStructuralElements
  );
  const [protectedSides] = useSessionStorage(
    SESSION_STORAGE_KEYS.steelProtectedSides
  );
  const [fireRating] = useSessionStorage(SESSION_STORAGE_KEYS.steelFireRating);
  const hasProtectedSides = useSteelHasProtectedSides();

  const orientationId = inputParametersFromResults?.orientation ?? structuralElements?.orientation;
  const fireRatingId = inputParametersFromResults?.fireRatingId ?? fireRating?.fireRating;
  const criticalTemperatureId = inputParametersFromResults?.criticalTemperatureId ?? fireRating?.criticalTemperature;
  const criticalTemperatureC = inputParametersFromResults?.criticalTemperatureC ?? fireRating?.customCriticalTemperature;
  const sectionFactor = inputParametersFromResults?.sectionFactor ?? structuralElements?.sectionFactor;
  const crossSectionalShapeId = inputParametersFromResults?.crossSectionalShapeId ?? structuralElements?.sectionType;
  const profileSubTypeId = inputParametersFromResults?.profileSubTypeId ?? structuralElements?.profileSubtype;
  const protectedSideId = inputParametersFromResults?.protectedSideId ?? protectedSides;

  const getRequestContent = (): SteelInputDetailsRequest => {
    if (hasProtectedSides) {
      return {
        orientationId,
        fireRatingId,
        criticalTemperatureId: Number(criticalTemperatureId),
        criticalTemperatureC: Number(criticalTemperatureC),
        profileSubTypeId: Number(profileSubTypeId),
        protectedSideId,
      };
    }

    return {
      orientationId,
      fireRatingId,
      criticalTemperatureId: Number(criticalTemperatureId),
      criticalTemperatureC: Number(criticalTemperatureC),
      sectionFactor: Number(sectionFactor),
      crossSectionalShapeId: Number(crossSectionalShapeId),
    };
  };

  const request: SteelInputDetailsRequest = getRequestContent();

  const { mutate, isLoading, data } = useMutation<
    SteelInputDetailsRequest,
    SteelInputDetailsData
  >(`${API_BASE_URL}v1/products/steel/calculation-input-details`, request);

  return {
    inputDetails: data,
    getSteelInputDetails: mutate,
    isLoading,
  };
};

export default useGetSteelInputDetails;
