import { useTranslation } from 'react-i18next';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import {
  CalculationResultData,
  InputDetailsData,
  ProductDetailsData,
} from 'shared/types';
import mapCalculationResult from 'shared/utils/mapCalculationResult';
import mapInputDetails from 'shared/utils/mapInputDetails';
import mapInstallationDetails from 'shared/utils/mapInstallationDetails';
import DescriptionPdf from './components/description-pdf';
import FooterPdf from './components/footer-pdf';
import HeaderPdf from './components/header-pdf';
import ImagePdf from './components/image-pdf';
import TablePdf from './components/table-pdf';
import styles from './styles';

interface PdfTemplateProps {
  productDetails?: ProductDetailsData;
  calculationResult?: CalculationResultData;
  inputDetails?: InputDetailsData;
}

const PdfTemplate = ({
  productDetails,
  calculationResult,
  inputDetails,
}: PdfTemplateProps) => {
  const { t } = useTranslation();
  const calculationDetails = mapCalculationResult(t, calculationResult);
  const installationDetails = mapInstallationDetails(
    t,
    calculationResult?.testReportNo,
    productDetails?.installationDetails
  );
  const mappedInputDetails = mapInputDetails(t, inputDetails);

  const removeRegSymbol = (str: string) => str.replace(/®/g, '');

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <HeaderPdf />
        {productDetails && (
          <Text style={styles.productName}>
            {removeRegSymbol(productDetails.name)}
          </Text>
        )}
        <View style={styles.wrapper}>
          {productDetails && (
            <View style={styles.contentLeft}>
              <ImagePdf imageId={productDetails.imageIds[0]} />
              <DescriptionPdf
                description={removeRegSymbol(productDetails.description)}
                benefits={productDetails.benefits}
              />
            </View>
          )}
          <View style={styles.contentRight}>
            <TablePdf
              title={t('productDetails.calculationResult')}
              data={calculationDetails}
            />
            <TablePdf
              title={t('productDetails.inputDetails')}
              data={mappedInputDetails}
            />
            <TablePdf
              title={t('productDetails.installationDetails')}
              data={installationDetails}
            />
          </View>
        </View>
        <FooterPdf />
      </Page>
    </Document>
  );
};

export default PdfTemplate;
