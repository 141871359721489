import env from '@beam-australia/react-env';

export const API_BASE_URL = env('API_BASE_URL');

export const DEFAULT_LANGUAGE = 'en';

export const ROUTES = {
  home: '/',
  countrySelect: '/country-select',
  structureSelect: '/structure-select',
  productSelect: '/product-select',
  steel: '/steel',
  structuralElements: 'structural-elements',
  protectedSides: 'protected-sides',
  environment: 'environment',
  fireRating: 'fire-rating',
  concrete: '/concrete',
  timber: '/timber',
  results: 'results',
  productDetails: 'product-details',
};

export const STEEL_ROUTES = {
  structuralElements: `${ROUTES.steel}/${ROUTES.structuralElements}`,
  protectedSides: `${ROUTES.steel}/${ROUTES.protectedSides}`,
  environment: `${ROUTES.steel}/${ROUTES.environment}`,
  fireRating: `${ROUTES.steel}/${ROUTES.fireRating}`,
  results: `${ROUTES.steel}/${ROUTES.results}`,
  productDetails: `${ROUTES.steel}/${ROUTES.productDetails}`,
};

export const CONCRETE_ROUTES = {
  structuralElements: `${ROUTES.concrete}/${ROUTES.structuralElements}`,
  environment: `${ROUTES.concrete}/${ROUTES.environment}`,
  fireRating: `${ROUTES.concrete}/${ROUTES.fireRating}`,
  results: `${ROUTES.concrete}/${ROUTES.results}`,
  productDetails: `${ROUTES.concrete}/${ROUTES.productDetails}`,
};

export const isInIframe = window.self !== window.top;

export const contactLinksByCountry: { [key: string]: string } = {
  AR: 'https://www.promat.com/es-ar/construccion/contacto/formulario/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  AU: 'https://www.promat.com/en-au/construction/contact-us/get-in-touch/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  BR: 'https://www.promat.com/pt-br/construcao/contato/como-falar-conosco/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  BG: 'https://www.promat.com/bg-bg/stroitelstvo/kontakti/formulyar-za-kontakt/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  CL: 'https://www.promat.com/es-cl/construccion/contacto/promat-chile/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  CO: 'https://www.promat.com/es-co/construccion/contactanos/formulario/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  HR: 'https://www.promat.com/hr-hr/graditeljstvo/nase-znanje-iskustvo/tehnicka-podrska/zatrazite-tehnicku-podrsku/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  FI: 'https://muotolevy.fi/ota-yhteytta/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  FR: 'https://www.promat.com/fr-fr/construction/contact/formulaire-contact/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  HU: 'https://www.promat.com/hu-hu/epiteszet/tudaskozpont/technikai-tamogatas/kerjen-tamogatast/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  IT: 'https://www.promat.com/it-it/edilizia/contattaci/get-in-touch/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  NL: 'https://www.promat.com/nl-nl/bouw/contact/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  PE: 'https://www.promat.com/es-pe/construccion/contacto/formulario/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  PL: 'https://www.promat.com/pl-pl/budownictwo/kontakt/formularz-kontaktowy/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  RO: 'https://www.promat.com/ro-ro/constructii/contactati-ne/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  RS: 'https://www.promat.com/sr-latn-rs/gradevinarstvo/kontakt/obrazac-za-kontakt/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  SK: 'https://www.promat.com/sk-sk/stavba/kontaktujte-nas/kontaktny-formular/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  SI: 'https://www.promat.com/sl-si/gradbenistvo/stik-z-nami/obrazec/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  ES: 'https://www.promat.com/es-es/construccion/contacto/contacte-nosotros/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
};

export const countryMultiLangLinks: {
  [key: string]: string;
} = {
  'fr-BE':
    'https://www.promat.com/fr-be/construction/contactez-nous/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
  'nl-BE':
    'https://www.promat.com/nl-be/bouw/contact/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta',
};

export const DEFAULT_CONTACT_LINK =
  'https://www.promat.com/en/construction/contact-us/get-in-touch/?utm_source=website&utm_medium=website&utm_campaign=structural-protection-calculator&utm_content=spc-cta';
