import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductDetails from 'features/product-details';
import { STEEL_ROUTES } from 'shared/constants';
import useGetSessionInputDetails from 'shared/hooks/useGetSessionInputDetails';
import useIsProductKnown from 'shared/hooks/useIsProductKnown';
import { SESSION_STORAGE_KEYS } from 'shared/sessionStorageUtils';
import { SteelInputDetailsData } from 'shared/types';
import { sortInputDetails } from 'shared/utils/sortInputDetails';
import useGetSteelCalculations from '../api/useGetSteelCalculations';
import useGetSteelInputDetails from '../api/useGetSteelInputDetails';
import { steelInputsSortOrder } from './constants';

const SteelProductDetails = () => {
  const { productId } = useParams();
  const isProductKnown = useIsProductKnown();
  const {
    calculationResult,
    getSteelCalculations,
    isLoading: isCalculationResultLoading,
  } = useGetSteelCalculations(productId);

  const {
    inputDetails,
    getSteelInputDetails,
    isLoading: isInputDetailsLoading,
  } = useGetSteelInputDetails();

  const backRoute = isProductKnown
    ? STEEL_ROUTES.fireRating
    : STEEL_ROUTES.results;

  useEffect(() => {
    getSteelCalculations();
    getSteelInputDetails();
  }, []);

  const sessionInputDetails = useGetSessionInputDetails(
    SESSION_STORAGE_KEYS.steelEnvironment
  );

  const steelInputDetails = { ...inputDetails, ...sessionInputDetails };

  const sortedInputDetails = sortInputDetails(
    steelInputsSortOrder,
    steelInputDetails as SteelInputDetailsData
  );

  const isProductDataLoading =
    isCalculationResultLoading || isInputDetailsLoading;

  return (
    <ProductDetails
      calculationResult={calculationResult}
      inputDetails={sortedInputDetails}
      isCalculationResultLoading={isProductDataLoading}
      backRoute={backRoute}
    />
  );
};
export default SteelProductDetails;
